import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Labelbox,
  Table,
  FrameBox,
  InfoTable,
  Button,
  Modal,
  ContractInvoiceView,
} from "../../components";
import { Grid, Paper, TableCell } from "@material-ui/core";
import "./payment.scss";
import { INVOICE_ACTION } from "../../redux/action";
import { dateFormatter } from "../../utils/common";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ZelleQR from "../../images/Zelle-QR-image.png";
import { font } from "../../resources/fonts/fontBase64";

const ContractPayment = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [invoices, setInvoices] = React.useState([]);
  const [InvoiceList, setInvoiceList] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState("");  // Track selected payment method
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState({
    state: false,
    data: {},
  });
  const [showECheckModal, setShowECheckModal] = React.useState(false);
  const { userId } = props;

  const USER_DATA = userId
    ? props.USER_DATA
    : JSON.parse(localStorage.getItem("USER_DATA"));

    const [isButtonEnabled, setButtonEnabled] = React.useState(false); // State for enabling/disabling the button

  useMemo(() => {
    if (location.state?.data)
      setModalOpen({ state: true, data: location.state.data });
  }, [location]);

  useLayoutEffect(() => {
 let InvoiceList = dispatch(
 INVOICE_ACTION.getAllInvoices({
        billed_to: userId || localStorage.getItem("user_id"),
        status: 0,
      })
    );

  }, [userId, dispatch]);

  const {
    invoice: { allInvoices },
  } = useSelector((state) => state);

  useEffect(() => {
    const extractInvoiceNumber = (invoice_no) => parseInt(invoice_no.replace('INV-', ''));
    const validInvoices = allInvoices.filter((invoice) => Number(invoice.total) > 0);
    const sortedInvoices = [...validInvoices].sort((a, b) => extractInvoiceNumber(b.invoice_no) - extractInvoiceNumber(a.invoice_no));
    // Filter out invoices where the due amount (total - amount_paid) is 0
    const unpaidInvoices = sortedInvoices.filter((invoice) => {
      // Check for null or undefined explicitly
      if (invoice.total == null || invoice.amount_paid == null) {
        return false;  // exclude this invoice
      }
      // Now safe to subtract
      return (invoice.total - invoice.amount_paid) > 0;
    });
    
    setInvoices(
      unpaidInvoices.map((data) => {
        const { invoice_no, total, invoice_date, amount_paid, amount_due } = data;
       // const due = Math.max(total - amount_paid, 0);
        return {
          select: false,
          invoice_no,
          invoice_date: dateFormatter(invoice_date),
          amount: `$ ${total}`,
          due_amount: `$ ${amount_due}`,
        };
      })
    );
  }, [allInvoices]);

  const onChange = (value, key, rowIndex) => {
    invoices[rowIndex][key] = value;
    setInvoices([...invoices]);
  };

  useEffect(() => {
    const isAnyInvoiceSelected = invoices.some((invoice) => invoice.select);
    setButtonEnabled(isAnyInvoiceSelected);
  }, [invoices]);

  const total = useMemo(() => {
    return invoices.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.select
          ? Number(currentValue.due_amount.replace(/[^0-9.]/g, ""))
          : 0),
      0
    );
  }, [invoices]);
  // Zelle Payment Handler - Open Modal
  const handleZellePayment = () => {
    setModalOpen(true);
    setPaymentType("zelle");
    setTotalAmount(total);  // Capture the total amount at the time of payment
  };
 
  const handleAuthorizeNetPayment = async () => {
    setPaymentType("authorize.net");

    const apiLoginId = "5Yb6Hf9Mh";
    //const ngrokUrl = "https://5310-2601-647-4d7e-39ad-e13e-a0f4-9449-91e.ngrok-free.app";
    //const relayResponseUrl = `${ngrokUrl}/authorize-result`; // Updated API URL

    // Ensure at least one invoice is selected
    const selectedInvoices = invoices.filter((invoice) => invoice.select);
    if (selectedInvoices.length === 0) {
        alert("Please select at least one invoice to proceed.");
        return;
    }

    // Calculate total amount
    const totalAmount = total.toFixed(2);
    const timestamp = Math.round(Date.now() / 1000); // Current UNIX timestamp
    const sequence = Math.floor(Math.random() * 100000).toString(); // Unique per transaction

    try {
        // Request HMAC-MD5 hash from backend
       // const response = await fetch("https://app.finlotax.com/api/generate-hash", {   //Uncomment For Production
        const response = await fetch("https://staging.finlotax.com/api/generate-hash", { //Uncomment For Staging
        //const response = await fetch("http://localhost:5000/generate-hash", {   //Uncomment For Dev
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ amount: totalAmount, sequence, timestamp }),
        });

        const data = await response.json();

        if (!data.hashKey) {
            alert("Error generating hash. Please try again.");
            return;
        }

        // Create a form and submit it to secure.authorize.net
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://secure.authorize.net/gateway/transact.dll";

        // Add required fields
        const fields = {
            x_login: apiLoginId,
            x_amount: totalAmount,
            x_currency_code: "USD",
            x_description: "Invoice Payment",
            x_invoice_num: selectedInvoices.map((inv) => inv.invoice_no).join(", "),
            x_fp_sequence: sequence,
            x_fp_timestamp: timestamp,
            x_fp_hash: data.hashKey, // Use secure hash from backend
            x_show_form: "PAYMENT_FORM",
            x_relay_response: "FALSE",
            //x_relay_url: relayResponseUrl, // Redirects to backend for processing
        };

        Object.entries(fields).forEach(([key, value]) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
    } catch (error) {
      console.error("Authorize.Net Payment Error:", error);
        alert("Failed to generate payment request.");
    }
};



   // "Pay with authorize.net" button
  // const handleAuthorizeNetPayment = async () => {
  //   setPaymentType("authorize.net");
  //   setTotalAmount(total);
  //   setShowECheckModal(true);
  // };

  // Called when the user successfully pays with eCheck
  // const handleECheckSuccess = (response) => {
  //   console.log("ECheck payment success:", response);
  //   // ... you can refresh invoice data, show success message, etc.
  //   setShowECheckModal(false);
  // };

  // // Called when there's an error
  // const handleECheckError = (error) => {
  //   console.log("ECheck payment error:", error.transactionResponse, error.messages);
  // };

  // SwipePay Handler - Redirect
  // const handleSwipePay = () => {
  //   setPaymentType("swipepay");
  //   window.location.href = "https://www.swipepay.com/payment";  // Replace with actual SwipePay URL
  // };
  const onActionClick = (action, row, actionIndex) => {
    setModalOpen({ state: true, data: allInvoices[actionIndex] });
  };

  const fields = [
    {
      type: "checkbox",
      changeData: onChange,
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
  ];
  return (
    <FrameBox title="Invoice Payment">
      <Grid container spacing={3} className="paddingTop">
        {/* ... your existing layout ... */}

        <Grid item xs={12}>
          <Table
            variant="small"
            title="Select the Invoice / Invoices you want to pay"
            header={[
              { id: "1", label: "" },
              { id: "2", label: "Description / Invoice Ref. #" },
              { id: "3", label: "Invoice Date" },
              { id: "4", label: "Net Amount" },
              { id: "5", label: "Due Amount" },
            ]}
            data={invoices}
            fields={fields}
            onActionClick={onActionClick}
            customRow={
              <>
                <TableCell></TableCell>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>$ {total.toFixed(2)}</TableCell>
              </>
            }
          />
        </Grid>
        {/* Terms checkbox, etc. */}
        <Grid item xs={12}>
          <Labelbox
            type="checkbox"
            label={
              <span className="terms">
                I have read the{" "}
                <a
                  href="https://finlotax.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms &amp; Conditions, Privacy Policy, Disclaimer
                </a>{" "}
                and proceed to pay via following payment methods
              </span>
            }
            checked
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className="payment-notes">
            <span className="payment-notes-msg">Notes:</span>
            <ul>
              <li>
                Please ensure that you are sending the payment to the correct
                recipient - accounts@finlotax.com
              </li>
              <li>
                Transactions made via Zelle are instant and cannot be reversed
              </li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} className="flex flex-center">
          <Button
            disable={!isButtonEnabled}
            text={
              <div>
                <img
                  src={require("../../images/zelle-logo.png")}
                  alt="Zelle"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Pay with Zelle
              </div>
            }
            large
            handleClick={handleZellePayment}
          />

          <Button
            disable={!isButtonEnabled}
            text={
              <div>
                <img
                  src={require("../../images/authorize.jpeg")}
                  alt="authorize.net"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Pay with authorize.net
              </div>
            }
            large
            handleClick={handleAuthorizeNetPayment}
          />
        </Grid>
      </Grid>

      {/* Existing Invoice Preview Modal */}
      {isModalOpen.state && (
        <Modal
          open={isModalOpen.state}
          title="Invoice Preview"
          maxWidth
          handleClose={() => setModalOpen({ state: false, data: {} })}
        >
          <ContractInvoiceView data={isModalOpen.data} label="Invoice" />
        </Modal>
      )}

      {/* Zelle QR Code Modal */}
      {isModalOpen && paymentType === "zelle" && (
        <Modal
          open={isModalOpen}
          title="Scan QR Code to Pay with Zelle"
          handleClose={() => setModalOpen(false)}
          maxWidth
        >
          <div
            className="qr-container"
            style={{
              fontSize: "18px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: "10px 0" }}>
              Total Amount to be paid: <strong>${totalAmount.toFixed(2)}</strong>
            </p>
          </div>
          <Grid item xs={12}>
            <Paper className="payment-notes">
              <ul>
                <li>
                  Scan the barcode using your banking app or Zelle app to send
                  your payment securely.
                </li>
                <li>
                  Ensure the correct amount is entered before confirming the
                  transaction.
                </li>
              </ul>
            </Paper>
          </Grid>
          <div
            className="qr-container"
            style={{
              fontSize: "18px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <iframe
              src={ZelleQR}
              width="500px"
              height="440px"
              title="Zelle QR Code"
            />
          </div>
        </Modal>
      )}

      {/* ECheck Modal for Authorize.Net */}
      {/* <AuthorizeNetECheckForm
        open={showECheckModal}
        onClose={() => setShowECheckModal(false)}
        totalAmount={totalAmount}
        selectedInvoices={invoices.filter((inv) => inv.select)}
        onPaymentSuccess={handleECheckSuccess}
        onPaymentError={handleECheckError}
      /> */}
    </FrameBox>
  );
};

export default ContractPayment;
