import {
  CLIENT_ACTION,
  INVOICE_ACTION,
  CONTRACT_ACTION,
} from "../../redux/action";


const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric"
});

const ReportStructure = {
  "Sign Up / Registration": [
    {
      label: "Registration reports",
      API: CLIENT_ACTION.getOverAllClients,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "63", label: "Handled By" },
        { id: "77", label: "Location" },
        { id: "88", label: "Type" },
        { id: "89", label: "Status" },
        { id: "83", label: "Source" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
  ],
  "Invoice / Contracts": [
    {
      label: "Collection/invoice report",
      API: INVOICE_ACTION.getCollectionInvoices,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "3", label: "Invoice No." },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "6", label: "Handled By" },
        { id: "7", label: "Location" },
        { id: "8", label: "Type" },
        { id: "9", label: "Invoice Amount" },
        { id: "10", label: "Invoice Status" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
    {
      label: "Revenue by Associate (Employee)",
      API: INVOICE_ACTION.getAllInvoices,

      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "3", label: "Invoice No." },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "6", label: "Handled By" },
        { id: "7", label: "Location" },
        { id: "8", label: "Type" },
        { id: "3", label: "Invoice Amount" },
        { id: "3", label: "Invoice Status" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
        {
          type: "select",
          labelname: "Employee",
          value: 1,
          dropdown: [],
          key: "employee_id",
        },
      ],
    },
    {
      label: "Contract report",
      API: CONTRACT_ACTION.getAllContracts,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "3", label: "Contract No./Invoice No." },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "6", label: "Handled By" },
        { id: "7", label: "Location" },
        { id: "8", label: "Type" },
        { id: "3", label: "Contract Amount" },
        { id: "3", label: "Contract Status" },
        { id: "8", label: "Approval Date" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
  ],
  "Payments / Revenue": [
    {
      label: "Revenue comparison report- Monthly/Quarterly/yearly",
      API: INVOICE_ACTION.getAllInvoices,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "3", label: "Invoice No." },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "6", label: "Handled By" },
        { id: "7", label: "Location" },
        { id: "8", label: "Type" },
        { id: "9", label: "Invoice Amount" },
        { id: "10", label: "Invoice Status" },
        { id: "11", label: "Payment Date" },
        { id: "12", label: "Mode of Payment" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
    {
      label: "Revenue report- Location and employee/Associate wise",
      API: INVOICE_ACTION.getAllInvoices,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "3", label: "Invoice No." },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "6", label: "Handled By" },
        { id: "7", label: "Location" },
        { id: "8", label: "Type" },
        { id: "11", label: "Payment Date" },
        { id: "12", label: "Mode of Payment" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
        {
          type: "select",
          labelname: "Location",
          value: 1,
          dropdown: [],
          key: "location_id",
        },
        {
          type: "select",
          labelname: "Employee",
          value: 1,
          dropdown: [],
          key: "employee_id",
        },
      ],
    },
  ],
  Clients: [
    {
      label: "Client list- All clients",
      API: CLIENT_ACTION.getOverAllClients,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "63", label: "Handled By" },
        { id: "77", label: "Location" },
        { id: "88", label: "Type" },
        { id: "89", label: "Status" },
        { id: "83", label: "Source" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
    {
      label: "Client list- Individual",
      API: CLIENT_ACTION.getIndividualClients,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "63", label: "Handled By" },
        { id: "77", label: "Location" },
        { id: "88", label: "Type" },
        { id: "89", label: "Status" },
        { id: "83", label: "Source" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
    {
      label: "Client list- Business",
      API: CLIENT_ACTION.getBusinessClients,
      headers: [
        { id: "1", label: "Date" },
        { id: "2", label: "Customer ID" },
        { id: "3", label: "Client Name" },
        { id: "4", label: "Email" },
        { id: "5", label: "Phone" },
        { id: "63", label: "Handled By" },
        { id: "77", label: "Location" },
        { id: "88", label: "Type" },
        { id: "89", label: "Status" },
        { id: "83", label: "Source" },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
  ],
  Timesheet: [
    {
      label: "Timesheet reports",
      headers: [
        { id: "1", label: "Employee Name" },
        { id: "2", label: "Supervisor Name" },
        { id: "3", label: "Date" },
        { id: "4", label: "Location" },
        { id: "5", label: "Client Name" },
        { id: "63", label: "Customer ID" },
        { id: "77", label: "Task Type" },
        { id: "88", label: "Task Description" },
        { id: "89", label: "Billable Hours" },
        { id: "83", label: "Non billable Hours " },
        {
          id: "83",
          label: "Toral Hours (K+L)",
        },
      ],
      filters: [
        {
          type: "datepicker",
          labelname: "Start Date",
          value: formattedDate,
          key: "start_date",
        },
        {
          type: "datepicker",
          labelname: "End Date",
          value: formattedDate,
          key: "end_date",
        },
      ],
    },
  ],
};
export default ReportStructure;
