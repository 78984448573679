import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../../utils/token-service";

const getAssignedBy = (seperator) => {
  return (TokenService.getUserRole() === "employee" || TokenService.getUserRole() === "manager")
    ? (seperator ? seperator : "?") + "assigned_by=" + TokenService.getUserID()
    : "";
};
const insertUpdateClient = async (state) => {
  try {
    const { params, insertUpdateFlag, client_id } = state;

    const API_NAME =
      insertUpdateFlag === 0
        ? "client/createClient"
        : `client/updateClientById/${client_id}`;
    const data = await API.post(`${API_NAME}`, params);
    return data;
  } catch (error) {
    return [];
  }
};

const handleActivateClients = async (selected_clients, user_email) => {
  try {
   
   console.log("INSIDE CLIENT ACTION CLIENTS TO ACTIVATE: ",selected_clients);
   console.log("INSIDE CLIENT ACTION CLIENTS TO ACTIVATE: ",user_email);
    const data = await API.post(`client/activateClients/${user_email}`, selected_clients);
    return data;
  } catch (error) {
    return [];
  }
};
const updateRegisteredClientStatus = async (state) => {
  try {
    const data = await API.post(`client/updateRegisteredClientStatus`, state);
    return data;
  } catch (error) {
    return [];
  }
};

const getActiveClients = createAsyncThunk(
  "client/getActiveClientsStatus",
  async () => {
    try {
      let clientMaster = [];
      const {
        data: { data },
      } = await API.get(`client/getAllClient?active=true`);
      if (data?.length > 0) {
        clientMaster = data.map(({ client_id, first_name, last_name }) => {
          return { id: client_id, value: `${first_name} ${last_name}` };
        });
      }
      return { data, clientMaster };
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getDashboardCardDetails = createAsyncThunk(
  "client/getDashboardCardDetailsStatus",
  async ({ user_id }) => {
    try {
      const url = `client/dashboardCardDetails${
        user_id ? "?user_id=" + user_id : ""
      }${getAssignedBy(user_id && "&")}`;
      
      //console.log("Inside FE Dashboard Details, Generated URL:", url);
      
      const {
        data: { data },
      } = await API.get(url);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getRegisteredClients = createAsyncThunk(
  "client/getRegisteredClientsStatus",
  async () => {
    try {
      const {
        data: { data },
      } = await API.get(`client/getAllClient?registered=true`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

// const getAllClients = createAsyncThunk(
//   "client/getAllClientsStatus",
//   async () => {
//     try {
//       const {
//         data: { data },
//       } = await API.get(
//         `client/getAllClient?overAll=true${getAssignedBy("&")}`
//       );
//       return data;
//     } catch (error) {
//       console.log(error);
//       return [];
//     }
//   }
// );

const getAllClients = createAsyncThunk(
  "client/getAllClientsStatus",
  async (filters) => {
    try {
      let query = `client/getAllClient?overAll=true${getAssignedBy("&")}`;
      
      //if (filters.start_date) query += `&start_date=${filters.start_date}`;
      //if (filters.end_date) query += `&end_date=${filters.end_date}`;

      const { data } = await API.get(query);
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);


const getSearchClient = createAsyncThunk(
  "client/getSearchClientStatus",
  async (state) => {
    try {
      const {
        data: { data },
      } = await API.post(`client/getSearchClient${getAssignedBy("?")}`, state);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);
// for reports
const getOverAllClients = createAsyncThunk(
  "client/getOverAllClientsStatus",
  async (filters = {}) => {
    try {
      const { start_date, end_date } = filters;
      
      const query = new URLSearchParams();
      if (start_date) query.append("start_date", start_date);
      if (end_date) query.append("end_date", end_date);
      
      const { data: { data } } = await API.get(`client/getAllClient?${query.toString()}`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);


const getIndividualClients = createAsyncThunk(
  "client/getIndividualClientsStatus",
  async (filters = {}) => {
    try {
      const { start_date, end_date } = filters;

      const query = new URLSearchParams();
      if (start_date) query.append("start_date", start_date);
      if (end_date) query.append("end_date", end_date);
      query.append("individual", true);

      const { data: { data } } = await API.get(`client/getAllClient?${query.toString()}`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getBusinessClients = createAsyncThunk(
  "client/getBusinessClientsStatus",
  async (filters = {}) => {
    try {
      const { start_date, end_date } = filters;

      const query = new URLSearchParams();
      if (start_date) query.append("start_date", start_date);
      if (end_date) query.append("end_date", end_date);
      query.append("business", true);

      const { data: { data } } = await API.get(`client/getAllClient?${query.toString()}`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);



export {
  insertUpdateClient,
  updateRegisteredClientStatus,
  getActiveClients,
  getSearchClient,
  getAllClients,
  getRegisteredClients,
  getDashboardCardDetails,
  getOverAllClients,
  getIndividualClients,
  getBusinessClients,
  handleActivateClients,
};
