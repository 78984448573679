import React, { useEffect, useState, useMemo } from "react";
import { Table, FrameBox } from "../../components";
import { Grid, Paper, Avatar, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { CLIENT_ACTION } from "../../redux/action";
import { CONTRACT_ACTION } from "../../redux/action";
import { INVOICE_ACTION } from "../../redux/action";

import { dateFormatter } from "../../utils/common";
import _JSON from "./structure";
import { Invoice, Handshake, RegisterClient, Payment } from "../../images";
import { makeStyles } from "@material-ui/core/styles";
import "./reports.scss";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "20%",
      flexBasis: "20%",
    },
    // [theme.breakpoints.only("sm")]: {
    //   maxWidth: "33.3%",
    //   flexBasis: "33.3%",
    // },
  },
}));

const Reports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const STRUCTURE_JSON = { ..._JSON };
  const formattedDate = new Date().toISOString().split("T")[0];
  const [registrationFilters, setRegistrationFilters] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [invoiceFilters, setInvoiceFilters] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [contractFilters, setContractFilters] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [clientFiltersAll, setClientFiltersAll] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [clientFiltersIndividual, setClientFiltersIndividual] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [clientFiltersBusiness, setClientFiltersBusiness] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });
  const [revenueTimeWiseFilters, setRevenueTimeWiseFilter] = useState({
    start_date: formattedDate,
    end_date: formattedDate,
  });

  const [selectedReport, setSelectedReport] = useState({
    key: "",
    data: [],
  });
  const [tableData, setTableData] = useState({
    "Registration reports": [],
    "Collection/invoice report": [],
    "Revenue by Associate (Employee)": [],
    "Contract report": [],
    "Client list- All clients": [],
    "Client list- Individual": [],
    "Client list- Business": [],
  });
  console.log(selectedReport, STRUCTURE_JSON[selectedReport], "selectedReport");
  const {
    client: { overAllClients, businessClients, individualClients },
    invoice: { allInvoices, collectionInvoices },
    contract: { allContracts },
  } = useSelector((state) => state);

  const icons = [RegisterClient, Invoice, Payment, Handshake];
  
  useMemo(() => {
    if (overAllClients) {
      const registrationData = overAllClients.map((data) => {
        const {
          first_name, last_name, phone, email, employee, source,
          client_type, branch_office, status, createdAt, client_id,
        } = data;
        return {
          createdAt: dateFormatter(createdAt),
          client_id,
          name: `${first_name} ${last_name}`,
          email,
          phone,
          employee,
          branch_office,
          client_type,
          status: status ? "Active " : "Inactive",
          source,
        };
      });
      setTableData((prev) => ({
        ...prev,
        "Registration reports": registrationData,
      }));
    }
  }, [overAllClients]);
  

  useMemo(() => {
    const invoiceData = allInvoices.map((data) => {
      const {
        bill_to_first_name, bill_to_last_name, bill_to_phone, bill_to_email,
        bill_from_first_name, bill_from_last_name, client_type, branch_office,
        status, client_id, invoice_no, total, invoice_date,
      } = data;
      return {
        createdAt: dateFormatter(invoice_date),
        client_id,
        bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
        invoice_no,
        bill_to_email,
        bill_to_phone,
        bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
        branch_office: branch_office || "",
        client_type: client_type || "",
        total,
        status: status ? "Paid " : "Pending",
        payment_date: "",
        mode_of_payment: "",
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Collection/invoice report": invoiceData,
    }));
  }, [allInvoices]);


  useMemo(() => {
    const invoiceData = allInvoices.map((data) => {
      const {
        bill_to_first_name,
        bill_to_last_name,
        bill_to_phone,
        bill_to_email,
        bill_from_first_name,
        bill_from_last_name,
        client_type,
        branch_office,
        status,
        client_id,
        invoice_no,
        total,
        invoice_date,
      } = data;
      return {
        createdAt: dateFormatter(invoice_date),
        client_id,
        bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
        invoice_no,
        bill_to_email,
        bill_to_phone,
        bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
        branch_office: branch_office || "",
        client_type: client_type || "",
        total,
        status: status ? "Paid " : "Pending",
        payment_date: "",
        mode_of_payment: "",
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Revenue comparison report- Monthly/Quarterly/yearly": invoiceData,
    }));
  }, [allInvoices]);
  

  useMemo(() => {
    const invoiceData = allInvoices.map((data) => {
      const {
        bill_to_first_name,
        bill_to_last_name,
        bill_to_phone,
        bill_to_email,
        bill_from_first_name,
        bill_from_last_name,
        client_type,
        branch_office,
        status,
        client_id,
        invoice_no,
        total,
        invoice_date,
      } = data;
      return {
        createdAt: dateFormatter(invoice_date),
        client_id,
        bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
        invoice_no,
        bill_to_email,
        bill_to_phone,
        bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
        branch_office: branch_office || "",
        client_type: client_type || "",
        total,
        status: status ? "Paid " : "Pending",
        payment_date: "",
        mode_of_payment: "",
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Revenue report- Location and employee/Associate wise": invoiceData,
    }));
  }, [allInvoices]);

  useMemo(() => {
    const invoiceData = overAllClients.map((data) => {
      const {
        first_name,
        last_name,
        phone,
        email,
        employee,
        source,
        client_type,
        branch_office,
        status,
        createdAt,
        client_id,
      } = data;
      return {
        createdAt: dateFormatter(createdAt),
        client_id,
        name: `${first_name} ${last_name}`,
        email,
        phone,
        employee,
        branch_office,
        client_type,
        status: status ? "Active " : "Inactive",
        source,
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Client list- All clients": invoiceData,
    }));
  }, [overAllClients]);

  useMemo(() => {
    const invoiceData = individualClients.map((data) => {
      const {
        first_name,
        last_name,
        phone,
        email,
        employee,
        source,
        client_type,
        branch_office,
        status,
        createdAt,
        client_id,
      } = data;
      return {
        createdAt: dateFormatter(createdAt),
        client_id,
        name: `${first_name} ${last_name}`,
        email,
        phone,
        employee,
        branch_office,
        client_type,
        status: status ? "Active " : "Inactive",
        source,
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Client list- Individual": invoiceData,
    }));
  }, [individualClients]);

  useMemo(() => {
    const invoiceData = businessClients.map((data) => {
      const {
        first_name,
        last_name,
        phone,
        email,
        employee,
        source,
        client_type,
        branch_office,
        status,
        createdAt,
        client_id,
      } = data;
      return {
        createdAt: dateFormatter(createdAt),
        client_id,
        name: `${first_name} ${last_name}`,
        email,
        phone,
        employee,
        branch_office,
        client_type,
        status: status ? "Active " : "Inactive",
        source,
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Client list- Business": invoiceData,
    }));
  }, [businessClients]);

useMemo(() => {
    const contractData = allContracts.map((data) => {
      const {
        bill_to_first_name, bill_to_last_name, bill_to_phone, bill_to_email,
        bill_from_first_name, bill_from_last_name, client_type, branch_office,
        status, client_id, contract_no, total, contract_date,
      } = data;
      return {
        createdAt: dateFormatter(contract_date),
        client_id,
        bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
        contract_no,
        bill_to_email,
        bill_to_phone,
        bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
        branch_office,
        client_type,
        total,
        status: status ? "Approved " : "Pending",
        approval_date: "",
      };
    });
    setTableData((prev) => ({
      ...prev,
      "Contract report": contractData,
    }));
  }, [allContracts]);

  const onChange = (value, key, label) => {
    if (label === "Registration reports") {
      const filters = {
        start_date: key === "start_date" ? value : registrationFilters.start_date,
        end_date: key === "end_date" ? value : registrationFilters.end_date,
      };
      setRegistrationFilters(filters);
      dispatch(CLIENT_ACTION.getOverAllClients(filters));
    }

    if (label === "Collection/invoice report") {
      const filters = {
        start_date: key === "start_date" ? value : invoiceFilters.start_date,
        end_date: key === "end_date" ? value : invoiceFilters.end_date,
      };
      setInvoiceFilters(filters);
      dispatch(INVOICE_ACTION.getAllInvoices(filters));
    }

    if (label === "Contract report") {
      const filters = {
        start_date: key === "start_date" ? value : contractFilters.start_date,
        end_date: key === "end_date" ? value : contractFilters.end_date,
      };
      setContractFilters(filters);
      dispatch(CONTRACT_ACTION.getAllContracts(filters));
    }

    if (label === "Client list- All clients") {
      const filters = {
        start_date: key === "start_date" ? value : clientFiltersAll.start_date,
        end_date: key === "end_date" ? value : clientFiltersAll.end_date,
      };
      setClientFiltersAll(filters);
      dispatch(CLIENT_ACTION.getOverAllClients(filters));
    }

    if (label === "Client list- Individual") {
      const filters = {
        start_date: key === "start_date" ? value : clientFiltersIndividual.start_date,
        end_date: key === "end_date" ? value : clientFiltersIndividual.end_date,
      };
      setClientFiltersIndividual(filters);
      dispatch(CLIENT_ACTION.getIndividualClients(filters));
    }

    if (label === "Client list- Business") {
      const filters = {
        start_date: key === "start_date" ? value : clientFiltersBusiness.start_date,
        end_date: key === "end_date" ? value : clientFiltersBusiness.end_date,
      };
      setClientFiltersBusiness(filters);
      dispatch(CLIENT_ACTION.getBusinessClients(filters));
    }

    if (label === "Revenue comparison report- Monthly/Quarterly/yearly") {
      const filters = {
        start_date: key === "start_date" ? value : revenueTimeWiseFilters.start_date,
        end_date: key === "end_date" ? value : revenueTimeWiseFilters.end_date,
      };
      setRevenueTimeWiseFilter(filters);
      dispatch(INVOICE_ACTION.getAllInvoices(filters));
    }
  };

  const reports = Object.keys(STRUCTURE_JSON).map((report_key, index) => {
    return {
      id: report_key,
      value: report_key,
      icons: icons[index],
    };
  });

  const onChangeData = (selectedId) => {
    const returnAPIS = STRUCTURE_JSON[selectedId].map((item) => item.API);
    for (let i = 0; i <= returnAPIS.length - 1; i++) {
      const DYNAMIC_API = returnAPIS[i];
      DYNAMIC_API && dispatch(DYNAMIC_API());
    }
  };

  const onChangeReport = (selectedId) => {
    onChangeData(selectedId);
    setSelectedReport({
      key: selectedId,
      data: STRUCTURE_JSON[selectedId],
    });
  };

  return (
    <FrameBox title="Reports">
      <Grid container spacing={1}>
        {reports?.map((option, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            classes={{ item: classes.gridItem }}
          >
            <Paper
              key={index}
              className="report_card"
              onClick={() => onChangeReport(option.id)}
            >
              <div className="notify-tile">
                <Divider
                  orientation="vertical"
                  flexItem
                  className={`divider ${
                    option.id === selectedReport.key && "show"
                  }`}
                />
                <Avatar alt="" src={option.icons} className="notify-avatar" />
                <div className="message-tile">
                  <div className="notify-message notify-all-message">
                    {option.value}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} className="paddingTop">
        {selectedReport.data.map((report_data) => {
          return (
            <Grid item xs={12}>
              <Table
                showExporter
                variant="small"
                title={report_data.label}
                header={report_data.headers}
                data={tableData[report_data.label] || []}
                filters={report_data.filters}
                filterCallback={(value, key) =>
                  onChange(value, key, report_data.label)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </FrameBox>
  );
};

export default Reports;
