/* eslint-disable no-undef */
import React, {
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
  
} from "react";
import {
  Labelbox,
  InfoTable,
  FrameBox,
  Table,
  Button,
  Modal,
  ContractInvoiceView,
  Spinner,
} from "../../components";
import { Dollar } from "../../images";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import "./contract.scss";
import {
  INVOICE_ACTION,
  CONTRACT_ACTION,
  CLIENT_ACTION,
  EMPLOYEE_ACTION,
} from "../../redux/action";
import {
  Toaster,
  dateFormatter,
  dateTimeFormatter,
  dropdownName,
  wait,
  detailsBind,
  Loader,
  addressFormator,
  getDocumentBlob,
  cancelValidation,
  onSubmitValidation,
  checkValidation,
} from "../../utils/common";
import ValidationLibrary from "../../utils/validationfunction";
import { useReactToPrint } from "react-to-print";
import {
  billed_from_keys,
  billed_from_values,
  billed_to_keys,
  billed_to_business_values,
  billed_to_personal_values,
  editKeys,
} from "./contants";
import PaymentStatusModal from "../../components/PaymentStatusModal";

const CreatePage = () => {
  const CONTRACT = {
    insertAPI: CONTRACT_ACTION.insertUpdateContract,
    deleteAPI:CONTRACT_ACTION.deleteContractById,
    getAPI: CONTRACT_ACTION.getAllContracts,
    label: "Contract",
    dateKey: "contract_date",
    primaryID: "client_contract_id",
    subID: "client_contract_item_id",
    no: "contract_no",
  };
  const INVOICE = {
    insertAPI: INVOICE_ACTION.insertUpdateInvoice,
    deleteAPI:INVOICE_ACTION.deleteInvoiceById,
    getAPI: INVOICE_ACTION.getAllInvoices,
    label: "Invoice",
    dateKey: "invoice_date",
    primaryID: "client_invoice_id",
    subID: "client_invoice_item_id",
    no: "invoice_no",
  };
  const dispatch = useDispatch();
  const location = useLocation();

  const customRef = useRef(null)
  const divRef = useRef(null);
  const [clientDetails, setClientDetails] = useState(location.state);

  const componentRef = useRef();
  const [IC_DATA, setIC_DATA] = useState([]);

  const submitValidateRef = React.createRef();
  const cancelValidateRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [markPaidModal, setMarkPaidModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchTimeoutRef = useRef(null);
  const markPaidRef = useRef();
  const undoPaidRef = useRef(null);

  // const [MAIN_JSON, setMAIN_JSON] = useState(
  //   clientDetails?.mode === "invoice" ? INVOICE : CONTRACT
  // );
  const [MAIN_JSON, setMAIN_JSON] = useState(INVOICE);

  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
const [selectedInvoice, setSelectedInvoice] = useState(null);

const handlePaymentStatusOpen = (invoice) => {
  setSelectedInvoice(invoice);
  setPaymentStatusModal(true);
};

const handlePaymentStatusClose = () => {
  setPaymentStatusModal(false);
  setSelectedInvoice(null);
};
  const defaultValues = (dateKey) => {
    return {
      [dateKey || MAIN_JSON.dateKey]: {
        value: dateFormatter(false, true),
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      due_date: {
        value: dateFormatter(false, true),
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      description: {
        value: "",
        validation: [
          { name: "required" },
          { name: "custommaxLength", params: "255" },
        ],
        error: null,
        errmsg: null,
      },
      billed_by: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      billed_to: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      discount_status: {
        value: false,
        // validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      discount_type: {
        value: "",
        error: null,
        errmsg: null,
      },
      discount: {
        value: "",
        error: null,
        errmsg: null,
      },
      discount_referral_status: {
        value: false,
        error: null,
        errmsg: null,
      },
      discount_referral_value: {
        value: "25",
        error: null,
        errmsg: null,
      },
      
      sub_total: {
        value: "",
      },
      total: {
        value: "",
      },
      itemDetails: [
        { item_name: "", description: "", quantity: "", rate: "", amount: 0 },
      ],
      /// billed from details
      bill_from_first_name: {
        value: "",
      },
      bill_from_last_name: {
        value: "",
      },
      bill_from_email: {
        value: "",
      },
      bill_from_address_1: {
        value: "",
      },
      bill_from_address_2: {
        value: "",
      },
      bill_from_phone: {
        value: "",
      },
      bill_from_country: {
        value: "",
      },
      bill_from_state: {
        value: "",
      },
      bill_from_city: {
        value: "",
      },
      bill_from_zipcode: {
        value: "",
      },
      /// billed to details
      bill_to_first_name: {
        value: "",
      },
      bill_to_last_name: {
        value: "",
      },
      bill_to_email: {
        value: "",
      },
      bill_to_business_name: {
        value: "",
      },
      bill_to_business_type: {
        value: "",
      },
      bill_to_address_1: {
        value: "",
      },
      bill_to_address_2: {
        value: "",
      },
      bill_to_phone: {
        value: "",
      },
      bill_to_country: {
        value: "",
      },
      bill_to_state: {
        value: "",
      },
      bill_to_city: {
        value: "",
      },
      bill_to_zipcode: {
        value: "",
      },
    };
  };

  const editModeDefault = {
    mode: false,
    [MAIN_JSON.primaryID]: 0,
    currentData: [],
    currentDataItemRemoved: [],
  };

  const {
    contract: { allContracts },
    employee: { activeEmployees },
    client: { activeClients, allClients },
    invoice: { allInvoices },
    master: { payment_mode },
  } = useSelector((state) => state);

  //console.log(allClients, "allClients");
  const initialDataLoad = () => {
    if (clientDetails?.mode && clientDetails.data) {
      let clientData = detailsBind(
        billed_to_keys,
        [
          ...(clientDetails.data.client_type === "Business"
            ? billed_to_business_values
            : billed_to_personal_values),
        ],
        clientDetails.data
      );
      const defaults = defaultValues(
        clientDetails?.mode === "invoice" && INVOICE.dateKey
      );
      return {
        ...defaults,
        ...clientData,
        billed_to: {
          ...defaults.billed_to,
          value: clientDetails.data.client_id,
        },
      };
    } else {
      return defaultValues();
    }
  };

  const [fieldConfiguration, setFieldConfiguration] = React.useState(
    initialDataLoad()
  );
  const [documentContent, setDocumentContent] = useState({
    state: false,
    data: {},
  });

  useLayoutEffect(() => {
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(CLIENT_ACTION.getActiveClients());
    if (clientDetails?.mode) {
      divRef.current.focus();
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);
 
  const onAction = async (type, actionData, actionIndex) => {
    if (type === "done") {
      setMarkPaidModal(true);
      markPaidRef.current = { data: IC_DATA[actionIndex] };
      return;
    } else if  (type === "paymentStatus"){
      handlePaymentStatusOpen(IC_DATA[actionIndex]);
    }
    else if (type === "print") {
      setDocumentContent({
        state: false,
        data: IC_DATA[actionIndex],
      });
      setTimeout(handlePrint, 100);
      return;
    } else if (type === "email") {
      Loader(true);
      setDocumentContent({
        state: false,
        data: IC_DATA[actionIndex],
      });
      setTimeout(handleSendMail, 100, IC_DATA[actionIndex]);
      return;
    } else if (type === "eye") {
      setDocumentContent({
        state: true,
        data: IC_DATA[actionIndex],
      });
      return;
    } else if (["undo", "close"].includes(type)) {
      undoPaidRef.current = { data: IC_DATA[actionIndex], type };
      setMarkPaidModal(true);
      return;
    }
    const selectedData = IC_DATA[actionIndex];

    if (type === "edit") {
   // onclick on edit smooth scroll
   customRef.current?.scrollIntoView( { behavior: "smooth"})

      const { discount_status, itemDetails, discount_referral_value } = selectedData;
      const itemMapDetails = itemDetails.map(
        ({ item_name, quantity, description, rate, amount, status }, index) => {
          return {
            item_name,
            description,
            quantity,
            rate,
            amount,
            status,
            [MAIN_JSON.subID]: itemDetails[index][MAIN_JSON.subID],
          };
        }
      );

      fieldConfiguration[MAIN_JSON.dateKey].value =
        selectedData[MAIN_JSON.dateKey];

      let details = {};
      if (discount_status === 1) {
        fieldConfiguration.discount_type.validation = [{ name: "required" }];
        fieldConfiguration.discount.validation = [{ name: "required" }];
      }

      if(discount_referral_value > 0.00){
        fieldConfiguration.discount_referral_status.value = true;
        fieldConfiguration.discount_referral_value.value = discount_referral_value;
      }

      editKeys.forEach((data) => {
        details[data] = {
          ...fieldConfiguration[data],
          value:
            data === "discount_status"
              ? selectedData[data] === 1
              : selectedData[data] === 0 || selectedData[data] || "",
        };
      });

      setEditMode({
        mode: true,
        [MAIN_JSON.primaryID]: selectedData[MAIN_JSON.primaryID],
        currentData: selectedData,
        currentDataItemRemoved: [],
      });

      setFieldConfiguration((prev) => ({
        ...prev,
        ...details,
        itemDetails: itemMapDetails,
      }));
    }
    else if (type == "delete"){
    
      try {
        const Id = selectedData[MAIN_JSON.primaryID]; // Adjust according to your data structure
        console.log("the contract id is",Id);
      // ? 
      if(MAIN_JSON.label === "Invoice"){
        const response =  await INVOICE.deleteAPI(Id) 
        console.log("the response after the deletion api", response["status"]);
        dispatch(MAIN_JSON.getAPI());
        if(response["status"] === 200)
        {
          Toaster.success("Invoice is successfully deleted.")
        }
        else{
          Toaster.error("Error Deleting the Invoice.")
        }
      }else{
        const response =  await  CONTRACT.deleteAPI(Id);
        console.log("the response after the deletion api", response["status"]);
        dispatch(MAIN_JSON.getAPI());
        if(response["status"] === 200)
          {
            Toaster.success("Contract is successfully deleted.")
          }
          else{
            Toaster.error("Error Deleting the Contract.")
          }
      }
      } catch (error) {
        Toaster.error(`Error occurred: ${error.message}`);
      }
    }

  };

  useMemo(() => {
    if (clientDetails?.mode) {
      const SELECTED_MODE =
        clientDetails.mode === "invoice" ? INVOICE : CONTRACT;
      dispatch(SELECTED_MODE.getAPI({ billed_to: clientDetails.client_id }));
      setMAIN_JSON(SELECTED_MODE);
      if (clientDetails?.view) {
        setDocumentContent({
          state: true,
          data: clientDetails.data,
        });
      }
    } else {
      dispatch(MAIN_JSON.getAPI());
    }
  }, [location]);

  const [editMode, setEditMode] = useState(editModeDefault);

  const onItemAction = async (type, actionData, index) => {
    // for delete the row
    if (actionData[MAIN_JSON.subID]) {
      setEditMode((prev) => ({
        ...prev,
        currentDataItemRemoved: [
          ...prev.currentDataItemRemoved,
          actionData[MAIN_JSON.subID],
        ],
      }));
    }
    // if (index)
    fieldConfiguration.itemDetails.splice(index, 1);
    calculation();
  };

  const calculation = (discount_value, discount_type, discount_status, discount_referral_status, discount_referral_value) => {
    let sub_total;

    if (!discount_value) {
        sub_total = fieldConfiguration.itemDetails.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.amount),
            0
        );
        fieldConfiguration.sub_total.value = sub_total.toFixed(2);
    } else {
        sub_total = Number(fieldConfiguration.sub_total.value).toFixed(2);
    }

    // Apply regular discount first
    let discounted_total = sub_total;
    if ((discount_value === false || discount_value !== "") && discount_status) {
        if (!discount_type) discount_type = fieldConfiguration.discount_type.value;

        if (discount_type === "Percentage") {
            discounted_total = (
                sub_total - (sub_total * (discount_value ? discount_value : fieldConfiguration.discount.value)) / 100
            ).toFixed(2);
        } else if (discount_type === "Amount") {
            discounted_total = (
                sub_total - (discount_value ? (discount_value === "" ? 0 : discount_value) : fieldConfiguration.discount.value)
            ).toFixed(2);
        }
    }

    if (discount_referral_status) {
        let referralDiscount = parseFloat(discount_referral_value || 25);
        discounted_total = (discounted_total - referralDiscount).toFixed(2);
    }

    if (discounted_total < 0) discounted_total = 0;

    fieldConfiguration.total.value = discounted_total;
    setFieldConfiguration({ ...fieldConfiguration });
};

const onChange = (value, key, rowIndex) => {
  if (key === "rate" || key === "quantity" || key === "discount" || key === "discount_referral_value")
      value = value.replace(/[^0-9.]/g, ""); // Ensure numeric input

  if (
      key === "discount_status" ||
      key === "discount" ||
      key === "discount_type" ||
      key === "discount_referral_status" ||
      key === "discount_referral_value"
  ) {
      calculation(
          key === "discount" ? value : fieldConfiguration.discount.value,
          key === "discount_type" ? value : fieldConfiguration.discount_type.value,
          key === "discount_status" ? (value ? 1 : 2) : 3,
          key === "discount_referral_status" ? value : fieldConfiguration.discount_referral_status.value,
          key === "discount_referral_value" ? value : fieldConfiguration.discount_referral_value.value
      );
  }

  if (key === "discount_referral_status") {
      setFieldConfiguration((prevState) => ({
          ...prevState,
          discount_referral_status: {
              ...prevState.discount_referral_status,
              value,
          },
          discount_referral_value: {
              ...prevState.discount_referral_value,
              value: value ? prevState.discount_referral_value.value || "25" : "",
          },
      }));
  }

  if (rowIndex || rowIndex === 0) {
      fieldConfiguration.itemDetails[rowIndex][key] = value;
      if (key === "rate" || key === "quantity") {
          fieldConfiguration.itemDetails[rowIndex].amount = (
              key === "quantity"
                  ? fieldConfiguration.itemDetails[rowIndex].rate * value
                  : fieldConfiguration.itemDetails[rowIndex].quantity * value
          ).toFixed(2);
          calculation();
      }
      setFieldConfiguration({ ...fieldConfiguration });
  } else {
      if (key === "billed_to" || key === "billed_by") {
          if (key === "billed_to")
              dispatch(MAIN_JSON.getAPI({ billed_to: value }));

          const selectedData = (
              key === "billed_to" ? activeClients : activeEmployees
          ).find((data) => {
              return (
                  data[key === "billed_to" ? "client_id" : "employee_id"] === value
              );
          });

          let billed_details = detailsBind(
              key === "billed_to" ? billed_to_keys : billed_from_keys,
              key === "billed_to"
                  ? [
                      ...(selectedData.client_type === "Business"
                          ? billed_to_business_values
                          : billed_to_personal_values),
                  ]
                  : billed_from_values,
              selectedData
          );

          setFieldConfiguration((prevState) => ({
              ...prevState,
              ...billed_details,
          }));
      }
      if (key === "discount_status") {
          setFieldConfiguration((prevState) => ({
              ...prevState,
              discount_type: {
                  ...fieldConfiguration.discount_type,
                  value: value ? fieldConfiguration.discount_type.value : "",
                  validation: [value ? { name: "required" } : {}],
              },
              discount: {
                  ...fieldConfiguration.discount,
                  value: value ? fieldConfiguration.discount.value : "",
                  validation: [value ? { name: "required" } : {}],
              },
          }));
      }

      const errorcheck = ValidationLibrary.checkValidation(
          value,
          fieldConfiguration[key].validation
      );
      const dynObj = {
          ...fieldConfiguration[key],
          value,
          error: !errorcheck.state,
          errmsg: errorcheck.msg,
      };

      setFieldConfiguration((prevState) => ({
          ...prevState,
          [key]: dynObj,
      }));
  }
};

const InvoiceStatus = {
  0: "Pending",
  1: "Paid",
  2: "Partially Paid",
  3: "Closed",
  null: "Pending",
};

useEffect(() => {
  if (searchTimeoutRef.current) {
    clearTimeout(searchTimeoutRef.current);
  }

  searchTimeoutRef.current = setTimeout(() => {
    dispatch(MAIN_JSON.getAPI({ billed_to: searchQuery }));
  }, 1000);

  return () => clearTimeout(searchTimeoutRef.current);
}, [searchQuery]);

const contractTableData = useMemo(() => {
  const RESPONSE = [...allContracts].filter(contract =>
    contract.bill_to_first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    contract.bill_to_last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    contract.bill_to_email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedData = RESPONSE.sort((a, b) =>
    a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
  );

  setIC_DATA(sortedData);
  return sortedData.map((data) => {
    const {
      contract_no,
      contract_date,
      amount_paid = 0, // Ensure default value is 0
      bill_from_first_name,
      bill_from_last_name,
      bill_to_first_name,
      bill_to_last_name,
      sub_total,
      total, // Net Total
      status,
      updatedAt,
    } = data;

    const amountDue = Math.max(total - amount_paid, 0).toFixed(2);

    return {
      contract_no,
      contract_date: dateFormatter(contract_date),
      bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
      bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
      sub_total: `$ ${sub_total}`,
      total: `$ ${total}`, // Net Total
      amount_due: `$ ${amountDue}`,
      status:
        !status || status === 0
          ? "Pending"
          : status === 1
          ? "Proceeded to invoice"
          : "Inactive",
      updatedAt: dateTimeFormatter(updatedAt),
      enabledActions:
        !status || status === 0
          ? { print: true, email: true, edit: true, delete: true }
          : { print: true, email: true },
    };
  });
}, [allContracts, searchQuery]);


const invoiceTableData = useMemo(() => {
  const RESPONSE = [...allInvoices].filter(invoice =>
    invoice.bill_to_first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.bill_to_last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.bill_to_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.bill_from_first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.bill_from_last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.bill_from_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    invoice.invoice_no.includes(searchQuery)
  );

  const sortedData = RESPONSE.sort((a, b) =>
    a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
  );

  setIC_DATA(sortedData);
  return sortedData.map((data) => {
    const {
      invoice_no,
      invoice_date,
      amount_paid = 0,
      bill_from_first_name,
      bill_from_last_name,
      bill_to_first_name,
      bill_to_last_name,
      sub_total,
      total, // Net Total
      status,
      updatedAt,
    } = data;

    const amountDue = Math.max(total - amount_paid, 0).toFixed(2);

    return {
      invoice_no,
      contract_date: dateFormatter(invoice_date),
      bill_from_name: `${bill_from_first_name} ${bill_from_last_name}`,
      bill_to_name: `${bill_to_first_name} ${bill_to_last_name}`,
      sub_total: `$ ${sub_total}`,
      total: `$ ${total}`, // Net Total
      amount_due: `$ ${amountDue}`,
      status: InvoiceStatus[status],
      updatedAt: dateTimeFormatter(updatedAt),
      enabledActions:
        status === 0
          ? {
              print: true,
              email: true,
              done: true,
              close: true,
              edit: true,
              paymentStatus: true,
              delete: true,
            }
          : { print: true, email: true, undo: status !== 3 },
    };
  });
}, [allInvoices, searchQuery]);

  
  const employeesData = useMemo(() => {
    //console.log("the active employees are", activeEmployees)
    return activeEmployees.map((data) => {
      const { employee_id } = data;
      return {
        id: employee_id,
        value: dropdownName(data),
      };
    });
  }, [activeEmployees]);

  const clientsData = useMemo(() => {
    //console.log("the active employees are", activeClients)

    return activeClients.map((data) => {
      const { client_id, email } = data;
      return {
        id: client_id,
        value: dropdownName(data) + " - " + email,
      };
    });
  }, [activeClients]);

  const defaultFields = [
    {
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      dropdown: [
        {
          id: "Tax Planning & Consulation",
          value: "Tax Planning & Consulation",
        },
        { id: "Individual Tax Services", value: "Individual Tax Services" },
        { id: "Business Tax Services", value: "Business Tax Services" },
        {
          id: "Business Formation Services",
          value: "Business Formation Services",
        },
        {
          id: "Bookkeeping and Payroll Services",
          value: "Bookkeeping and Payroll Services",
        },
        {
          id: "Misc. Tax and Compliance Services",
          value: "Misc. Tax and Compliance Services",
        },
      ],
      changeData: onChange,
    },
    {
      type: "textarea",
      validation: [{ name: "custommaxLength", params: "255" }],
      error: null,
      errmsg: null,
      changeData: onChange,
      maxRows: 1,
    },
    {
      type: "text",
      validation: [
        { name: "required" },
        { name: "custommaxLength", params: "3" },
      ],
      error: null,
      errmsg: null,
      changeData: onChange,
    },
    {
      type: "text",
      validation: [
        { name: "required" },
        { name: "custommaxValue", params: "99999" },
      ],
      error: null,
      errmsg: null,
      changeData: onChange,
    },
    {
      type: "label",
    },

    {
      type: "label",
    },
    {
      type: "label",
    },
  ];

  const defaultMarkPaidFields = {
    payment_mode_id: {
      value: "",
      labelname: "Mode of payment",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      dropdown: payment_mode,
    },
    transaction_id: {
      value: "",
      labelname: "Transaction number",
      type: "text",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    reference_number: {
      value: "",
      labelname: "Reference number",
      type: "text",
      // validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    payment_date: {
      value: "",
      labelname: "Payment date",
      type: "datepicker",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    payment_amount_paid: {
      value: "",
      labelname: "Amount",
      type: "text",
      disabled: true,
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    payment_comments: {
      value: "",
      labelname: "Comments",
      type: "textarea",
      maxRows: 1,
    },
  };

  const defaultUpdateStatusFields = {
    reason: {
      value: "",
      labelname: "Reason",
      type: "text",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
  };

  async function onSubmit(actionType) {
    try {
      if (["markAsPaid", "statusUpdation"].includes(actionType?.type)) {
        const response = await INVOICE_ACTION.invoicePaidUpdate(
          actionType.payload,
          actionType?.type
        );
        if (response?.status === "Success") {
          Toaster.success(
            `Payment ${
              actionType?.type === "markAsPaid" ? "updated" : "reverted"
            } successfully`
          );
          dispatch(MAIN_JSON.getAPI());
          setMarkPaidModal(false);
          markPaidRef.current = null;
          undoPaidRef.current = null;
        } else {
          Toaster.error(`Process not successfully held`);
        }
        return;
      }
  
      const mainvalue = {};
      const targetkeys = Object.keys(fieldConfiguration);
      for (const i in targetkeys) {
        if (targetkeys[i] === "itemDetails") break;
        const errorcheck = ValidationLibrary.checkValidation(
          fieldConfiguration[targetkeys[i]].value,
          fieldConfiguration[targetkeys[i]].validation
        );
        fieldConfiguration[targetkeys[i]].error = !errorcheck.state;
        fieldConfiguration[targetkeys[i]].errmsg = errorcheck.msg;
        mainvalue[targetkeys[i]] = fieldConfiguration[targetkeys[i]].value;
      }
      const filtererr = targetkeys.filter(
        (obj) => fieldConfiguration[obj].error === true
      );
  
      if (
        new Date(fieldConfiguration.due_date.value) <
          new Date(fieldConfiguration[MAIN_JSON.dateKey]?.value) ||
        filtererr.length > 0
      ) {
        Toaster.error("Please fill up required fields");
      } else {
        const checkValidity = submitValidateRef.current;
        const filterError = checkValidity();
        if (filterError) {
          Toaster.error("Please fill up required fields");
        } else {
          Loader(true);
          let itemParams = [];
  
          if (editMode.currentDataItemRemoved.length > 0) {
            const selectedData = editMode?.currentData;
            itemParams = editMode.currentDataItemRemoved.map((_id) => {
              let { item_name, quantity, description, rate, amount } =
                selectedData.itemDetails.find(
                  (data) => data[MAIN_JSON.subID] === _id
                );
              return {
                item_name,
                quantity,
                description,
                rate,
                amount,
                [MAIN_JSON.subID]: _id,
                status: 0,
              };
            });
            itemParams = [...fieldConfiguration.itemDetails, ...itemParams];
          } else {
            itemParams = fieldConfiguration.itemDetails;
          }
  
          const payload = {};
          const index = targetkeys.indexOf("itemDetails");
          if (index !== -1) {
            targetkeys.splice(index, 1);
          }
  
          targetkeys.forEach(
            (data) => (payload[data] = fieldConfiguration[data].value)
          );
  
          // **Ensure `discount_referral_value` is set to 0 if the checkbox is unchecked**
          payload["discount_referral_value"] = fieldConfiguration.discount_referral_status.value
            ? fieldConfiguration.discount_referral_value.value
            : "0";
  
          // **Ensure `amount_paid` is set to 0 for a new invoice or retain its existing value**
          payload["amount_paid"] =
            editMode.mode && editMode.currentData.amount_paid
              ? editMode.currentData.amount_paid
              : "0";
  
          // **Ensure `status` is set to 0 for new invoices or retain its value for updates**
          payload["status"] =
            editMode.mode && editMode.currentData.status !== undefined
              ? editMode.currentData.status
              : "0";
  
          let payloadData = {
            ...payload,
            itemDetails: JSON.stringify(itemParams),
          };
  
          let formData = new FormData();
          if (editMode.mode) {
            setDocumentContent({
              state: false,
              data: {
                ...payloadData,
                [MAIN_JSON.no]: editMode.currentData[MAIN_JSON.no],
                itemDetails: fieldConfiguration.itemDetails,
              },
            });
  
            await wait(2000);
            formData.append("file", await getDocumentBlob());
            formData.append(MAIN_JSON.no, editMode.currentData[MAIN_JSON.no]);
          }
  
          Object.entries(payloadData).forEach(([formKey, formValue]) => {
            formData.append(formKey, formValue);
          });
  
          const response = await MAIN_JSON.insertAPI({
            params: formData,
            insertUpdateFlag: editMode.mode ? 1 : 0,
            [MAIN_JSON.primaryID]: editMode[MAIN_JSON.primaryID],
          });
  
          if (response.status === 200 && response.data.status === 1) {
            if (editMode.mode === false) {
              setLoading(true);
              setDocumentContent({
                state: false,
                data: response.data.data,
              });
              setTimeout(handleSendMail, 2000, response.data.data, true);
            }
            Toaster.success(
              `${[MAIN_JSON.label]} ${
                !editMode.mode ? "created" : "updated"
              } successfully`
            );
            handleCancel();
          } else if (response.status === 200 && response.data.status === 0) {
            Toaster.warning(response.data.message);
          } else {
            Toaster.error(
              `Error occurred while ${
                !editMode.mode ? "creating" : "updating"
              } the ${MAIN_JSON.label}`
            );
          }
        }
      }
      setFieldConfiguration((prevState) => ({
        ...prevState,
      }));
    } catch (err) {
      Toaster.error(err);
    }
  }
  
  function handleCancel() {
    const cancel = cancelValidateRef.current;
    cancel();
    dispatch(MAIN_JSON.getAPI());
    setFieldConfiguration(defaultValues());
    editMode.mode && setEditMode(editModeDefault);
  }

  

  const modeChange = (action) => {
    const SELECTED_MODE = action ? INVOICE : CONTRACT;
    setMAIN_JSON(SELECTED_MODE);
    setFieldConfiguration(defaultValues(SELECTED_MODE.dateKey));
    editMode.mode && setEditMode(editModeDefault);
    setClientDetails();
    dispatch(SELECTED_MODE.getAPI());
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function handleSendMail(contentData, create) {
    let formData = new FormData();
    formData.append("file", await getDocumentBlob());

    const response = await CONTRACT_ACTION.sendEmail({
      binaryData: formData,
      create: create || false,
      origin: MAIN_JSON.label,
      id: contentData[MAIN_JSON.primaryID],
    });
    if (create) {
      setLoading(false);
    }
    if (response.status === 200 && response.data.status === 1) {
      Toaster.success(`Attachment has been successfully sent to the client `);
    } else if (response.status === 200 && response.data.status === 0) {
      Toaster.warning(response.data.message);
    } else {
      Toaster.error(`Error occurred while sending the attachment`);
    }
  }

  const View = ({ id }) => (
    <ContractInvoiceView
      inputRef={componentRef}
      id={id || false}
      data={documentContent.data}
      label={MAIN_JSON.label}
    />
  );

  const MarkAsPaid = () => {
    const undoMode = undoPaidRef.current;
    const [markPaidFields, setMarkPaidFields] = useState(
      undoMode ? defaultUpdateStatusFields : defaultMarkPaidFields
    );
    const keys = Object.keys(markPaidFields);

    useEffect(() => {
      if (markPaidRef?.current) {
        setMarkPaidFields((prev) => ({
          ...prev,
          payment_amount_paid: {
            ...prev.payment_amount_paid,
            value: markPaidRef.current.data.total,
          },
        }));
      }
    }, [markPaidRef]);

    const handleSubmit = () => {
      const isValid = onSubmitValidation({
        state: markPaidFields,
        setState: setMarkPaidFields,
      });
      if (!isValid.length) {
        let payload = {};
        for (let key in markPaidFields) {
          payload[key] = markPaidFields[key].value;
        }
        onSubmit({
          type: undoMode ? "statusUpdation" : "markAsPaid",
          payload: undoMode
            ? {
                ...payload,
                invoice_id: undoMode.data.client_invoice_id,
                status: undoMode.type === "undo" ? 0 : 3, //status notation {undo:0,close:3}
              }
            : {
                ...payload,
                invoice_id: markPaidRef.current.data.client_invoice_id,
              },
        });
      }
    };
    
    
    
    return (
      <Grid
        container
        spacing={3}
        className={`contract-padding ${undoMode && "undo-content"}`}
      >
        {keys.map((data) => (
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Labelbox
              {...markPaidFields[data]}
              value={markPaidFields[data].value || ""}
              changeData={(value) =>
                checkValidation({
                  key: data,
                  value: value,
                  state: markPaidFields,
                  setState: setMarkPaidFields,
                })
              }
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <div className="hbox">
            <Button text={`Submit`} large handleClick={handleSubmit} />
            <Button
              text="Cancel"
              large
              handleClick={() => {
                cancelValidation({
                  setState: setMarkPaidFields,
                  defaultState: defaultMarkPaidFields,
                });
                setMarkPaidModal(false);
                markPaidRef.current = null;
                undoPaidRef.current = null;
              }}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems="center" spacing={2} className="grid-padding" ref={customRef}>
      <div style={{ display: "none" }} >
        {Object.keys(documentContent.data).length > 0 && (
          <View id="divToPrint" />
        )}
      </div>
      <Grid item xs={12}>
        <Grid container spacing={2} className="paddingTop">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="btn-wrapper-left"
          >
            <div className="TitlePane">
              {editMode.mode ? "Update " : "Create "}
              {MAIN_JSON.label}
            </div>
            {/* {MAIN_JSON.label !== "Invoice" && (
  <Button
    iconCls="btn_icon"
    icon={<AddCircleOutlineOutlined />}
    text="Create Invoice"
    handleClick={() => modeChange(true)}  // Always switch to Invoice mode
  />
)} */}
{/* {             
            <Button
              iconCls="btn_icon"
              icon={<AddCircleOutlineOutlined />}
              // text={
              //   "Create " + 
              //   (MAIN_JSON.label === "Contract" ? "Invoice" : "Contract")
              // }
              handleClick={() => modeChange(MAIN_JSON.label === "Contract")}
            /> } */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="contract-padding">
        {editMode.mode && (
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Labelbox
              labelname={`${MAIN_JSON.label} No.`}
              type="text"
              value={editMode?.currentData[MAIN_JSON.no]}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Labelbox
            type="datepicker"
            labelname={`${MAIN_JSON.label} Date`}
            changeData={(e) => onChange(e, MAIN_JSON.dateKey)}
            value={fieldConfiguration[MAIN_JSON.dateKey]?.value}
            error={fieldConfiguration[MAIN_JSON.dateKey]?.error}
            errmsg={fieldConfiguration[MAIN_JSON.dateKey]?.errmsg}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Labelbox
            type="datepicker"
            labelname="Due Date"
            disablePast
            minDate={fieldConfiguration[MAIN_JSON.dateKey]?.value}
            changeData={(data) => onChange(data, "due_date")}
            minDateMessage={`Date must be after ${MAIN_JSON.label?.toLowerCase()} date`}
            value={fieldConfiguration.due_date.value}
            error={
              fieldConfiguration.due_date.error ||
              new Date(fieldConfiguration.due_date.value) <
                new Date(fieldConfiguration[MAIN_JSON.dateKey]?.value)
            }
            errmsg={fieldConfiguration.due_date.errmsg}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Labelbox
            type="textarea"
            labelname={`${MAIN_JSON.label} title/subject`}
            changeData={(e) => onChange(e, "description")}
            value={fieldConfiguration.description.value}
            error={fieldConfiguration.description.error}
            errmsg={fieldConfiguration.description.errmsg}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={6} md={6} lg={6} className="full-height">
          <FrameBox title="Billed By (Your Details)" variant="small">
            <Labelbox
              type="select"
              labelname=""
              changeData={(e) => onChange(e, "billed_by")}
              value={fieldConfiguration.billed_by.value}
              required
              dropdown={employeesData}
              error={fieldConfiguration.billed_by.error}
              errmsg={fieldConfiguration.billed_by.errmsg}
            />
            <Paper>
              <InfoTable
                info={{
                  name: "FinloTax Inc",
                  address: "1190 Miraloma Way Ste P, Sunnyvale, CA 94085",
                  email: "info@finlotax.com",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FrameBox
            title={`Billed To (${MAIN_JSON.label} Details)`}
            variant="small"
          >
            <Labelbox
              type="select"
              labelname=""
              changeData={(e) => onChange(e, "billed_to")}
              value={fieldConfiguration.billed_to.value}
              required
              dropdown={clientsData}
              error={fieldConfiguration.billed_to.error}
              errmsg={fieldConfiguration.billed_to.errmsg}
            />
            <Paper>
              <InfoTable
                // titles={["Name", "Email", "Phone","Address","City","Country"]}
                info={
                  fieldConfiguration.billed_to.value && {
                    name: `${
                      fieldConfiguration?.bill_to_first_name.value || ""
                    } ${fieldConfiguration?.bill_to_last_name.value || ""}`,
                    business_name:
                      fieldConfiguration?.bill_to_business_name.value,
                    email: fieldConfiguration?.bill_to_email.value,
                    phone: fieldConfiguration?.bill_to_phone.value || "",
                    address: addressFormator(
                      fieldConfiguration?.bill_to_address_1.value,
                      fieldConfiguration?.bill_to_address_2.value
                    ),
                    details: addressFormator(
                      fieldConfiguration?.bill_to_city.value,
                      fieldConfiguration?.bill_to_state.value,
                      fieldConfiguration?.bill_to_zipcode.value
                    ),
                    country: addressFormator(
                      fieldConfiguration?.bill_to_country.value
                    ),
                  }
                }
              />
            </Paper>
          </FrameBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="paddingTop" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>

          <Table
            variant="small"
            title="Add Items"
            header={[
              { id: "1", label: "Item" },
              { id: "2", label: "Description" },
              { id: "3", label: "Quantity" },
              { id: "4", label: "Rate" },
              { id: "5", label: "Amount" },
              { id: "6", label: "Action" },
            ]}
            data={fieldConfiguration.itemDetails}
            fields={defaultFields}
            hiddenFields={[
              "client_contract_item_id",
              "client_invoice_item_id",
              "status",
            ]}
            actions={["delete"]}
            // actionDefault
            onActionClick={onItemAction}
            actionElements={
              <Button
                iconCls="btn_icon"
                icon={<AddCircleOutlineOutlined />}
                text="Add"
                handleClick={() => {
                  fieldConfiguration.itemDetails.push({
                    item_name: "",
                    description: "",
                    quantity: "",
                    rate: "",
                    amount: 0,
                    status: 1,
                  });
                  setFieldConfiguration({ ...fieldConfiguration });
                }}
              />
            }
            validateRef={submitValidateRef}
            cancelValidate={cancelValidateRef}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="paddingTop" alignItems="center">
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Paper className="payment-notes" style={{ margin: "0.5rem" }}>
            <span className="payment-notes-msg">
              Please tick on the checkbox to enable discount provision
            </span>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "0.1rem" }}
            >
              <Grid item xs={12} sm={1} lg={1} md={1}>
                <Labelbox
                  type="checkbox"
                  labelname=""
                  changeData={(e) => onChange(e, "discount_status")}
                  checked={fieldConfiguration.discount_status.value}
                />
              </Grid>
              <Grid item xs={15} sm={5} lg={4} md={6}>
                <Labelbox
                  type="select"
                  className="discountselect"
                  labelname="Discount Type"
                  dropdown={[
                    { id: "Percentage", value: "Percentage" },
                    { id: "Amount", value: "Amount" },
                  ]}
                  changeData={(e) => onChange(e, "discount_type")}
                  value={fieldConfiguration.discount_type.value}
                  disabled={!fieldConfiguration.discount_status.value}
                  error={fieldConfiguration.discount_type.error}
                  errmsg={fieldConfiguration.discount_type.errmsg}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={3} md={5}>
                <Labelbox
                  type="text"
                  labelname="Discount Value"
                  changeData={(e) => onChange(e, "discount")}
                  value={fieldConfiguration.discount.value}
                  disabled={
                    !fieldConfiguration.discount_status.value ||
                    fieldConfiguration.discount_type.value === ""
                  }
                  error={fieldConfiguration.discount.error}
                  errmsg={fieldConfiguration.discount.errmsg}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "0.1rem" }}
            >
              <Grid item xs={12} sm={1} lg={1} md={1}>
              <Labelbox
                type="checkbox"
                labelname=""
                changeData={(e) => onChange(e, "discount_referral_status")}
                checked={fieldConfiguration.discount_referral_status.value}
              />
              </Grid>
              <Grid item xs={12} sm={4} lg={3} md={5}>
              <Labelbox
                type="text"
                labelname="Referral Discount Value"
                changeData={(e) => onChange(e, "discount_referral_value")}
                value={fieldConfiguration.discount_referral_value.value}
                disabled={!fieldConfiguration.discount_referral_status.value}
                error={fieldConfiguration.discount_referral_value.error}
                errmsg={fieldConfiguration.discount_referral_value.errmsg}
              />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={12} md={3} lg={3} className="flex">
          <div className="dollor_card">
            <div className="dollor-img">
              <img alt="dollar" src={Dollar} />
            </div>
            <InfoTable
              titles={["Gross Total", "Discount", "Referral Discount", "Net Total"]}
              info={{
                sub_total: `$ ${fieldConfiguration.sub_total.value}`,
                discount: `${
                  fieldConfiguration.discount_status.value &&
                  fieldConfiguration.discount.value !== ""
                    ? fieldConfiguration.discount_type.value === "Amount"
                      ? `$ ${fieldConfiguration.discount.value}` // Show as amount
                      : `$ ${(parseFloat(fieldConfiguration.sub_total.value || 0) * parseFloat(fieldConfiguration.discount.value || 0)) / 100}` // Calculate percentage
                    : "$ 0"
                }`,
                "Referral Discount": `$ ${
                  fieldConfiguration.discount_referral_status.value &&
                  fieldConfiguration.discount_referral_value.value !== ""
                    ? fieldConfiguration.discount_referral_value.value
                    : "0"
                }`,
                total: `$ ${fieldConfiguration.total.value}`,
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="hbox">
          <Button
            text={`${editMode.mode ? "Update" : "Submit"}`}
            large
            handleClick={onSubmit}
          />
          <Button text="Reset" large handleClick={handleCancel} />
        </div>
      </Grid>

      
      <Grid container spacing={3} className="paddingTop">
      <Grid item xs={12} ref={divRef}>
      <Grid item xs={12} style={{ marginBottom: "1rem", width: "40%" }}>
        <Labelbox
          type="text"
          labelname="Search Invoices"
          changeData={(value) => setSearchQuery(value)}
          value={searchQuery}
          placeholder="Search by Invoice No. / Billed To"
        />
      </Grid>
        <Table
          variant="small"
          title={`${MAIN_JSON.label}s`}
          header={[
            { id: "icon", label: "" },
            { id: "contract_no", label: `${MAIN_JSON.label} No.` },
            { id: "contract_date", label: `${MAIN_JSON.label} Date` },
            { id: "billed_by", label: "Billed By" },
            { id: "billed_to", label: "Billed To" },
            { id: "sub_total", label: "Gross Total" },
            { id: "total", label: "Net Total" }, // New Column
            { id: "amount_due", label: "Amount Due" }, // New Column
            { id: "status", label: "Status" },
            { id: "updated", label: "Last Updated" },
            { id: "action", label: "Action" },
          ]}
          data={
            MAIN_JSON.label === "Invoice"
              ? invoiceTableData
              : contractTableData
          }
          actions={["print", "email", "edit", "paymentStatus", "delete"]}
          onActionClick={onAction}
          icons={{ eyeIcon: true }}
        />
      </Grid>
    </Grid>

      {(documentContent.state || markPaidModal) && (
        <Modal
          open={documentContent.state || markPaidModal}
          title={
            documentContent.state
              ? `${MAIN_JSON.label} Preview`
              : undoPaidRef.current
              ? `${undoPaidRef.current?.type?.capitalize()} Payment`
              : "Payment Confirmation"
          }
          maxWidth={!undoPaidRef.current}
          handleClose={() => {
            if (documentContent.state)
              setDocumentContent({ state: false, data: {} });
            if (markPaidModal) setMarkPaidModal(false);
            markPaidRef.current = null;
            undoPaidRef.current = null;
          }}
        >
          {documentContent.state && <View />}
          {markPaidModal && <MarkAsPaid />}
        </Modal>
      )}
      {loading && <Spinner />}
      {paymentStatusModal && <PaymentStatusModal open={paymentStatusModal}
    handleClose={handlePaymentStatusClose} invoice={selectedInvoice} />
    }
    </Grid>
  );
};

export default CreatePage;
