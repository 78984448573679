import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { Labelbox, Table, FrameBox, InfoTable } from "../../components";
import { Dollar } from "../../images";
import { dateFormatter, addressFormator } from "../../utils/common";

export default function ContractProposal({ data, label, id, inputRef }) {
  const InvoiceStatus = {
    0: "Pending",
    1: "Paid",
    2: "Partially Paid",
    3: "Closed",
    null: "Pending",
  };
  return (
    <Grid
      container
      // direction="row"
      // justifyContent="center"
      alignItems="center"
      spacing={2}
      id={id}
      ref={inputRef}
      style={{ padding: 15 }}
    >
      <Grid container className="pdf-header">
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <img
            src="/resources/images/Finlotax.png"
            alt="logo"
            width={200}
          />
          <hr />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="contract-padding">
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={
              data[`${label === "Invoice" ? "invoice_no" : "contract_no"}`]
            }
            label={`${label} No.`}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={dateFormatter(
              data[`${label === "Invoice" ? "invoice_date" : "contract_date"}`]
            )}
            label={`${label} Date`}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={dateFormatter(data.due_date)}
            label="Due Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={data.description}
            label={`${label} title/subject`}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className="paddingTop">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="full-height preview-full-height"
        >
          <FrameBox
            title={`Billed By `}
            variant="small"
          >
            <Paper>
              <InfoTable
                info={{
                  name: <strong>FinloTax Inc</strong>,
                  address: "1190 Miraloma Way Ste P, Sunnyvale, CA 94085",
                  email: "accounts@finlotax.com",
                  billedby:`Billed by FinloTax Associate: ${data.bill_from_first_name} ${data.bill_from_last_name}`
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FrameBox
            title={`Billed To `}
            variant="small"
            className="full-height preview-full-height"
          >
            <Paper>
              <InfoTable
                // titles={["Name", "Email ID", "Phone"]}
                info={{
                  name: <strong>{data.bill_to_first_name}&nbsp;{data.bill_to_last_name}</strong>,
                  business_name: data.bill_to_business_name,
                  email: data.bill_to_email,
                  phone: data.bill_to_phone,
                  address: addressFormator(
                    data.bill_to_address_1,
                    data.bill_to_address_2
                  ),
                  details: addressFormator(
                    data.bill_to_city,
                    data.bill_to_state,
                    data.bill_to_zipcode
                  ),
                  country: addressFormator(data.bill_to_country),
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
      </Grid>
      {/* {data.status && data.status !== 0 ? (
        <Grid container spacing={3} className="paddingTop">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FrameBox title={`Client Comments`} variant="small">
              <Paper>{data.client_comment}</Paper>
            </FrameBox>
          </Grid>
        </Grid>
      ) : (
        <></>
      )} */}
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table
            variant="small"
            title="Items"
            header={[
              { id: "1", label: "Item" },
              { id: "2", label: "Description" },
              { id: "3", label: "Quantity" },
              { id: "4", label: "Rate" },
              { id: "5", label: "Amount" },
              { id: "6", label: "Due Amount" },
              { id: "7", label: "Payment Status" },
            ]}
            data={data?.itemDetails.map(
              ({ item_name, description, quantity, rate, amount, due_amount, status }) => {
                return { item_name, description, quantity, rate, amount,due_amount: Math.max((data?.total - data?.amount_paid),0).toFixed(2) , payment_status: InvoiceStatus[data?.status] };
              }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="flex">
          <div className="dollor_card">
            <div className="dollor-img">
              <img alt="dollar" src={Dollar} />
            </div>
            <InfoTable
              titles={["Gross Total", "Discount","Referral Discount", "Net Total"]}
              info={{
                sub_total: `$ ${data.sub_total}`,
                discount: `${
                  data.discount_status &&
                  data.discount !== ""
                    ? data.discount_type === "Amount"
                      ? `$ ${data.discount}` // Show as amount
                      : `$ ${(parseFloat(data.sub_total || 0) * parseFloat(data.discount || 0)) / 100}` // Calculate percentage
                    : "$ 0"
                }`,
                discount_referral_value: `${data.discount_referral_value !== ""
                    ? `$ ${data.discount_referral_value}`
                    : "$ 0"
                }`,
                total: `$ ${data.total}`,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
